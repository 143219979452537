import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography
} from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import RemoveHelpeeDialog from './RemoveHelpeeDialog';
import StartHelpHelpeeDialog from './StartHelpHelpeeDialog';
import StartAssistHelpeeDialog from './StartAssistHelpeeDialog';
import {makeFullNameWithNetIdText} from './commonHelperMethods';
import CancelHelpHelpeeDialog from './CancelHelpHelpeeDialog';
import StopAssistHelpeeDialog from './StopAssistHelpeeDialog';
import EditHelpeeDialog from './EditHelpeeDialog';
import JoinHelpeeDialog from './JoinHelpeeDialog';
import RoomUpdateDialog from './RoomUpdateDialog';
import RoomCategoryUpdateDialog from './RoomCategoryUpdateDialog';
import { getUserInfo, getUserNetId, hasAdminAuth } from './userInfo';
import RemoveAllWaitingHelpeesDialog from './RemoveAllWaitingHelpeesDialog';
import CheckoutAllStaffDialog from './CheckoutAllStaffDialog';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BedtimeOffIcon from '@mui/icons-material/BedtimeOff';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Edit } from '@mui/icons-material';
import HelpProposalDialog from './HelpProposalDialog';

const iconAndTextStyle = {display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'};

class HelpeeListComponent extends React.Component {
  handleRemove(helpee) {
    return () => {
      this.props.onRemove(helpee);
    };
  }

  handleStartHelp(helpee) {
    return () => {
      this.props.onStartHelp(helpee);
    };
  }

  handleStartAssist(helpee) {
    return () => {
      this.props.onStartAssist(helpee);
    };
  }

  handleFinishHelp(helpee) {
    return () => {
      this.props.onFinishHelp(helpee);
    };
  }

  handleCancelHelp(helpee) {
    return () => {
      this.props.onCancelHelp(helpee);
    };
  }

  handleStopAssist(helpee) {
    return () => {
      this.props.onStopAssist(helpee);
    };
  }

  handleEditHelpee(helpee) {
    return () => {
      this.props.onEditHelpee(helpee);
    };
  }

  handleEnterConference(helpee) {
    return () => {
      this.props.onEnterConference(helpee);
    };
  }

  handleCheckIn() {
    return () => {
      this.props.handleCheckIn();
    };
  }

  handleCheckOut() {
    return () => {
      this.props.handleCheckOut();
    };
  }

  render() {
    const tableBody = this.props.helpeeList.map((helpee) => (
      <TableRow
        key={helpee.helpee_user.helpee_id}
      >
        <TableCell>
          <Typography color="primary">
            <a
              href={'https://cs128.org/howdy/student/resolve/' + helpee.helpee_user.net_id + '/notes'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {helpee.helpee_user.first_name + ' ' + helpee.helpee_user.last_name}
            </a>
            {' (' + helpee.helpee_user.net_id + ')'}
          </Typography>
        </TableCell>
        <TableCell>
          {new Date(helpee.join_time).toLocaleString()}
        </TableCell>
        <TableCell>
          {helpee.new_category ? <a href={helpee.new_category_url} target="_blank" rel="noopener noreferrer">{helpee.new_category}</a> : helpee.category}
        </TableCell>
        <TableCell>
          {helpee.summary}
        </TableCell>
        <TableCell>
          {helpee.location}
        </TableCell>
        <TableCell>
          {helpee.conference_enabled ? (
            <Button onClick={this.handleEnterConference(helpee)}>
              ↗ Join
            </Button>
          ) : (
            <Tooltip title="User's conference is not enabled." arrow followCursor={true}>
              <ErrorIcon color="info" />
            </Tooltip>
          )}
          
        </TableCell>
        <TableCell>
        {helpee.status === 'waiting' || helpee.status === 'assigning' ? 
            (
              (this.props.isCheckedIn || this.props.adminView) ? (
                <Tooltip title="Student needs a primary helper first." arrow followCursor={true}>
                <ErrorIcon color="info" />
                </Tooltip>
              ) : (
                <Tooltip title="You are not checked in." arrow followCursor={true}>
                <ErrorIcon color="info" />
                </Tooltip>
              )
            ) : ( (helpee.status === 'helping' && helpee.sub_status === 'assisting') ? (
                <span style={{display: 'flex', color: 'purple',
                alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                <AddCircleIcon fontSize="inherit" sx={{paddingRight: '3px'}} />
                <Tooltip title={makeFullNameWithNetIdText(helpee.assistant_user)} arrow followCursor={true}>
                  <span style={{ fontWeight: 'bold', borderBottom: '1px dotted purple', textDecoration: 'none' }}>
                    {helpee.assistant_user.first_name}
                  </span>
                </Tooltip>
                <span style={{ fontWeight: '500' }}>
                  &nbsp;is assisting
                </span>
              </span>
            ) : (
                (this.props.isCheckedIn || this.props.adminView) ? (
                  <Button startIcon={<PersonAddIcon className="flipX"/>}
                  variant="contained" color="secondary" size="small" onClick={this.handleStartAssist(helpee)}>
                  Assist
                </Button>
              ) : (
                <Tooltip title="You are not checked in." arrow followCursor={true}>
                <ErrorIcon color="info" />
                </Tooltip>
              )
            ) 
          )
        }
        </TableCell>
        <TableCell>
          {helpee.status === 'waiting'  ?
              (
                  (this.props.isCheckedIn || this.props.adminView) ? (
                    <Button startIcon={<KeyboardReturnIcon className="flipX"/>}
                            variant="contained" color="primary" size="small" onClick={this.handleStartHelp(helpee)}>
                      Help
                    </Button>
                  ) : (
                    <Tooltip title="You are not checked in." arrow followCursor={true}>
                      <ErrorIcon color="info" />
                    </Tooltip>
                  )
              ): ( helpee.status === 'assigning' ? (
                <span style={{display: 'flex', color: 'red',
                  alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                  <Tooltip title={makeFullNameWithNetIdText(helpee.proposed_helper_user)} arrow followCursor={true}>
                    <span style={{ fontWeight: 'bold', borderBottom: '1px dotted red', textDecoration: 'none' }}>
                      {helpee.proposed_helper_user.first_name}
                    </span>
                  </Tooltip>
                  <span style={{ fontWeight: '500' }}>
                    &nbsp;is being assigned...
                  </span>
                </span>
              ) : (
                  <span style={{display: 'flex', color: 'green',
                    alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                    <CheckCircleIcon fontSize="inherit" sx={{paddingRight: '3px'}} />
                    <Tooltip title={makeFullNameWithNetIdText(helpee.helper_user)} arrow followCursor={true}>
                      <span style={{ fontWeight: 'bold', borderBottom: '1px dotted green', textDecoration: 'none' }}>
                        {helpee.helper_user.first_name}
                      </span>
                    </Tooltip>
                    <span style={{ fontWeight: '500' }}>
                      &nbsp;is helping
                    </span>
                  </span>
              )
          )}
        </TableCell>
        <TableCell>
          {helpee.status === 'waiting' || helpee.status === 'assigning' ? (
            <>
              {this.props.adminView ? (
                <ButtonGroup variant="contained" size="small" >
                  <Button variant="contained" size="small" color="warning" startIcon={<Edit />} onClick={this.handleEditHelpee(helpee)}>
                    Edit
                  </Button>
                  <Button variant="contained" size="small" color="error" startIcon={<CloseIcon />} onClick={this.handleRemove(helpee)}>
                    Remove
                  </Button>
                </ButtonGroup>
              ) : (
                <Tooltip title="Student needs to be helped first" arrow followCursor={true}>
                  <ErrorIcon color="info" />
                </Tooltip>
              )}
            </>
          ) : this.props.adminView || helpee.helper_user.net_id === getUserNetId() ? (
            <ButtonGroup variant="contained" size="small" >
              <Button variant="contained" size="small" color="warning" startIcon={<Edit />} onClick={this.handleEditHelpee(helpee)}>
                Edit
              </Button>
              <Button variant="contained" size="small" color="info" startIcon={<KeyboardReturnIcon className="flipY" />} onClick={this.handleCancelHelp(helpee)}>
                Unhelp
              </Button>
              <Button variant="contained" size="small" color="success" startIcon={<CheckIcon />} onClick={this.handleFinishHelp(helpee)}>
                Done
              </Button>
              <Button variant="contained" size="small" color="error" startIcon={<CloseIcon />} onClick={this.handleRemove(helpee)}>
                Missed
              </Button>
            </ButtonGroup>
          ) : helpee.sub_status === 'assisting' && helpee.assistant_user.net_id === getUserNetId() ? (
            <ButtonGroup variant="contained" size="small" >
            <Button variant="contained" size="small" color="success" startIcon={<ThumbUpIcon className="flipX" />} onClick={this.handleStopAssist(helpee)}>
              Finish Assisting
            </Button>
          </ButtonGroup>
          ) :
           ( 
            <>
              <Tooltip title="You are not the helper." arrow followCursor={true}>
                <ErrorIcon color="red" />
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    ));

    return (
      <div>
        { this.props.isCheckedIn ? (
          <Grid container spacing={2} mt={1} mb={4}>
            <Grid item xs={12} align="center">
              <Button variant="contained" color="error" size="large" sx={{mt: 2}} onClick={this.props.handleCheckOut}>
                Check Out { getUserNetId() }
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} mt={1} mb={6}>
            <Grid item xs={12} align="center">
              <Typography variant="h5" mb={2} sx={{fontWeight: "bold", display: 'flex',
                alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="primary">
                <EmojiPeopleIcon sx={{paddingRight: '7px'}} />
                { "Howdy, welcome back!" }
              </Typography>
              <Typography variant="body1" sx={{lineHeight: '1.7'}}>
                If you're ready to start helping, check in. <br />
                You won't be able to help anyone until you do.
              </Typography>
              <Button variant="contained" color="success" size="large" sx={{mt: 2}} onClick={this.props.handleCheckIn}>
                Check In { getUserNetId() }
              </Button>
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" sx={{fontWeight: "bold", display: 'flex',
          alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="primary">
            Currently Helping:
        </Typography>
        <Typography variant="body1" mb={2} sx={{display: 'flex',
          alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="primary">
          { this.props.shiftHelperList.length === 0 ? (
            "No one is currently checked in."
          ) : (
            this.props.shiftHelperList.join(', ')
          )}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <colgroup>
                <col width="15%" />
                <col width="15%" />
                <col width="5%" />
                <col width="20%" />
                <col width="10%" />
                <col width="5%" />
                <col width="7%" />
                <col width="23%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Join Time</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Question Summary</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Meeting</TableCell>
                <TableCell>Assist</TableCell>
                <TableCell>Status</TableCell> 
                <TableCell>Operation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBody}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
}

function HelperInfo(props) {
  // const [helpeeList, setHelpeeList] = useState([]);
  const [inited, setInited] = useState(false);
  const [dialogHelpee, setDialogHelpee] = useState({});
  const [dialogsOpen, setDialogsOpen] = useState({
    joinHelpHelpee: false, 
    removeHelpee: false, 
    startHelpHelpee: false,
    startAssistHelpee: false, 
    cancelHelpHelpee: false, 
    stopAssistHelpee: false,
    roomUpdate: false, 
    removeAllWaitingHelpees: false, 
    checkoutAllStaff: false,
    editHelpee: false,
  });
  const [adminView, setAdminView] = useState(false);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [proposal, setProposal] = useState(null);

  useEffect(() => {
    if (!inited) {
      setInited(true);
      props.onLoadHelpeeList();
      props.onLoadShiftHelperList();
      props.onLoadCurrentProposal();
      loadCheckInStatus();
    }
  });

  useEffect(() => {
    if (props.proposal) {
      const helpee = props.helpeeList.find(helpee => 
        helpee.helpee_user?.net_id === props.proposal.helpee_net_id
      );
      
      if (helpee) {
        setDialogHelpee(helpee);
        setProposal(props.proposal);
      } else {
        setProposal(null);
      }
    }
  }, [props.proposal, props.helpeeList]);

  const setDialogOpen = (dialog, open) => {
    let newDialogsOpen = { ...dialogsOpen };
    newDialogsOpen[dialog] = open;
    setDialogsOpen(newDialogsOpen);
  };

  const handleCloseDialog = (dialog) => () => {
    setDialogOpen(dialog, false);
  };

  const handleJoin = () => () => {
    setDialogOpen('joinHelpHelpee', true);
  };



  const handleRemove = () => (helpee) => {
    setDialogHelpee(helpee);
    setDialogOpen('removeHelpee', true);
  };

  const handleStartHelp = () => (helpee) => {
    setDialogHelpee(helpee);
    setDialogOpen('startHelpHelpee', true);
  };

  const handleStartAssist = () => (helpee) => {
    setDialogHelpee(helpee);
    setDialogOpen('startAssistHelpee', true);
  }

  const handleCancelHelp = () => (helpee) => {
    setDialogHelpee(helpee);
    setDialogOpen('cancelHelpHelpee', true);
  };

  const handleStopAssist = () => (helpee) => {
    console.log('we are here!');
    setDialogHelpee(helpee);
    setDialogOpen('stopAssistHelpee', true);
  };

  const handleEditHelpee = () => (helpee) => {
    setDialogHelpee(helpee);
    setDialogOpen('editHelpee', true);
  };

  const handleFinishHelp = () => (helpee) => {
    props.onStartLoading();
    const apiPath = '/api/rooms/' + props.roomInfo.room_id + '/helpers/finish_help';
    axios.get(apiPath, { params: {
        helpee_id: helpee.helpee_id, 
      } })
      .then(res => {
        props.onUpdateHelpeeList(res.data.data.update_info);
        props.onStopLoading();
      })
      .catch((error) => {
        props.onStopLoading();
        props.onApiThrow(apiPath, error);
      });
  };

  const handleRemoveAllWaitingHelpees = () => () => {
    setDialogOpen('removeAllWaitingHelpees', true);
  };
  const handleCheckoutAllStaff = () => () => {
    setDialogOpen('checkoutAllStaff', true);
  };

  const handleRoomUpdate = () => () => {
    setDialogOpen('roomUpdate', true);
  };

  const handleCategoryUpdate = () => () => {
    setDialogOpen('categoryUpdate', true);
  };

  const handleAdminViewSwitchChange = () => (event) => {
    setAdminView(event.target.checked);
  };

  const handleEnterConference = () => (helpee) => {
    props.onStartLoading();
    const apiPath = '/api/rooms/' + props.roomInfo.room_id + '/helpers/get_conference_link';
    axios.get(apiPath, { params: {
        helpee_id: helpee.helpee_id, 
      } })
      .then(res => {
        window.open(res.data.data.conference_link);
        props.onStopLoading();
      })
      .catch((error) => {
        props.onStopLoading();
        props.onApiThrow(apiPath, error);
      });
  }

  const handleCheckIn = () => () => {
    props.onStartLoading();
    const apiPath = '/api/shifts/check_in';
    axios.get(apiPath, { })
      .then(res => {
        setIsCheckedIn(true);
        props.onStopLoading();
      })
      .catch((error) => {
        props.onStopLoading();
        props.onApiThrow(apiPath, error);
      });
  }

  const handleCheckOut = () => () => {
    props.onStartLoading();
    const apiPath = '/api/shifts/check_out';
    axios.get(apiPath, { })
      .then(res => {
        setIsCheckedIn(false);
        props.onStopLoading();
      })
      .catch((error) => {
        props.onStopLoading();
        props.onApiThrow(apiPath, error);
      });
  }

  const loadCheckInStatus = () => {
    props.onStartLoading();
    const apiPath = '/api/shifts/check_in_status';
    axios.get(apiPath, { })
      .then(res => {
        setIsCheckedIn(res.data.data.is_checked_in);
        props.onStopLoading();
      })
      .catch((error) => {
        props.onStopLoading();
        props.onApiThrow(apiPath, error);
      });
  }

  const handleCloseProposalDialog = () => {
    setProposal(null);
    props.onClose();
  };

  // if (Object.keys(props.roomInfo).length === 0 || 
  //     Object.keys(props.helpeeList).length === 0) {
  if (Object.keys(props.roomInfo).length === 0) {
    return null;
  }

  return (
    <div>
      <RemoveHelpeeDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        helpee={dialogHelpee}
        open={dialogsOpen.removeHelpee}
        onClose={handleCloseDialog('removeHelpee')}
      />

      <StartHelpHelpeeDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        helpee={dialogHelpee}
        isOutOfOrder={props.helpeeList.filter(helpee => helpee.status === 'waiting').sort((a, b) => a.order - b.order).findIndex(h => h.helpee_id === dialogHelpee.helpee_id) !== 0}
        open={dialogsOpen.startHelpHelpee}
        onClose={handleCloseDialog('startHelpHelpee')}
      />

      <StartAssistHelpeeDialog
         onApiThrow={props.onApiThrow}
         onUpdateHelpeeList={props.onUpdateHelpeeList}
         roomInfo={props.roomInfo}
         helpee={dialogHelpee}
         open={dialogsOpen.startAssistHelpee}
         onClose={handleCloseDialog('startAssistHelpee')}
      />  

      <CancelHelpHelpeeDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        helpee={dialogHelpee}
        open={dialogsOpen.cancelHelpHelpee}
        onClose={handleCloseDialog('cancelHelpHelpee')}
      />

      <StopAssistHelpeeDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        helpee={dialogHelpee}
        open={dialogsOpen.stopAssistHelpee}
        onClose={handleCloseDialog('stopAssistHelpee')}
      />
      
      <EditHelpeeDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        helpee={dialogHelpee}
        open={dialogsOpen.editHelpee}
        onClose={handleCloseDialog('editHelpee')}
      />

      <JoinHelpeeDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        open={dialogsOpen.joinHelpHelpee}
        onClose={handleCloseDialog('joinHelpHelpee')}
        newCategories={props.newCategories}
      />

      <RemoveAllWaitingHelpeesDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        open={dialogsOpen.removeAllWaitingHelpees}
        onClose={handleCloseDialog('removeAllWaitingHelpees')}
      />

      <CheckoutAllStaffDialog
        onApiThrow={props.onApiThrow}
        onUpdateHelpeeList={props.onUpdateHelpeeList}
        roomInfo={props.roomInfo}
        open={dialogsOpen.checkoutAllStaff}
        onClose={handleCloseDialog('checkoutAllStaff')}
      />
      <RoomUpdateDialog 
        onApiThrow={props.onApiThrow}
        open={dialogsOpen.roomUpdate}
        roomId={props.roomInfo.room_id}
        onStartLoading={props.onStartLoading}
        onStopLoading={props.onStopLoading}
        onClose={handleCloseDialog('roomUpdate')}
        onUpdateRoomInfo={props.onUpdateRoomInfo}
        adminView={adminView}
      />

      <RoomCategoryUpdateDialog 
        onApiThrow={props.onApiThrow}
        open={dialogsOpen.categoryUpdate}
        roomId={props.roomInfo.room_id}
        onClose={handleCloseDialog('categoryUpdate')}
        onLoadRoomInfo={props.onLoadRoomInfo}
        onUpdateRoomInfo={props.onUpdateRoomInfo}
        adminView={adminView}
        newCategories={props.newCategories}
      />

      {proposal && (
        <HelpProposalDialog
          proposal={proposal}
          helpee={dialogHelpee}
          open={proposal !== null}
          onUpdateProposal={(newProposal) => {
            setProposal(newProposal);
          }}
          onUpdateHelpeeList={props.onUpdateHelpeeList}
          roomInfo={props.roomInfo}
          onClose={(handleCloseProposalDialog)}
          onApiThrow={props.onApiThrow}
          isOutOfOrder={false}
        />
      )}

      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Button variant="contained" onClick={handleRoomUpdate()}>room setting</Button>
            { adminView ? (
              <>
                <Button variant="contained" onClick={handleCategoryUpdate()} sx={{
                  marginLeft: 1
                }}>
                  manage categories
                </Button>
                  <Button variant="contained" color="error" onClick={handleCheckoutAllStaff()} sx={{
                  marginLeft: 1
                }}>
                  checkout all staff
                </Button>
              </>
            ) : null }
            { adminView ? (
              <Button variant="contained" color="warning" onClick={handleJoin()} sx={{
                marginLeft: 1
              }}>
                put a student to the queue
              </Button>
            ) : null }
            <Button variant="contained" color="error" onClick={handleRemoveAllWaitingHelpees()} sx={{
              marginLeft: 1
            }}>
              remove all waiting students
            </Button>
          </Grid>
          <Grid item align="right">
            { hasAdminAuth() ? (
                <React.Fragment>
                  <small style={{fontFamily: "'Ubuntu', sans-serif", color: 'gray'}}>
                    Admin View
                  </small>
                  <Switch
                    checked={adminView}
                    onChange={handleAdminViewSwitchChange()}
                  />
                </React.Fragment>
            ) : null }
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Typography variant="body1" style={iconAndTextStyle}>
            Queue status:&nbsp;
            {props.roomInfo.status === 'open' ? (
                <font color="green" style={iconAndTextStyle}>
                    <CheckCircleIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                    <span>Open</span> &nbsp;
                    { props.roomInfo.status_text != null && props.roomInfo.status_text !== '' ?
                        ('(' + props.roomInfo.status_text + ')') : '' }
                </font>
            ) : props.roomInfo.status === 'closed' && props.roomInfo.status_text === 'not on duty' ? (
                <font color="secondary" style={iconAndTextStyle}>
                    <BedtimeOffIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                    <span>Closed</span>
                </font>
            ) : props.roomInfo.status === 'closed' && props.roomInfo.status_text === 'high traffic' ? (
                <font color="#1976d2" style={iconAndTextStyle}>
                    <AcUnitIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                    <span>Temporarily Frozen</span>
                </font>
            ) : (
                <font color={ props.roomInfo.status === 'closed' ? 'error' : 'grey' } style={iconAndTextStyle}>
                    <DoNotDisturbOnIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                    <span style={{textTransform: 'capitalize'}}>
                        { props.roomInfo.status }
                    </span> &nbsp;
                    { props.roomInfo.status_text != null && props.roomInfo.status_text !== '' ?
                        ('(' + props.roomInfo.status_text + ')') : '' }
                </font>
            )}
            {/* <font color={ props.roomInfo.status === 'open' ? 'green' :
                props.roomInfo.status === 'closed' ? 'red' : 'grey' }>
              { props.roomInfo.status }
            </font>
            { props.roomInfo.status_text != null && props.roomInfo.status_text !== '' ?
                (' (' + props.roomInfo.status_text + ')') : '' } */}
          </Typography>
        </Grid>
      </Box>

      <Box mt={1}>
        <HelpeeListComponent
          helpeeList={props.helpeeList}
          shiftHelperList={props.shiftHelperList}
          onRemove={handleRemove()}
          onStartHelp={handleStartHelp()}
          onStartAssist={handleStartAssist()}
          onFinishHelp={handleFinishHelp()}
          onCancelHelp={handleCancelHelp()}
          onStopAssist={handleStopAssist()}
          onEditHelpee={handleEditHelpee()}
          onEnterConference={handleEnterConference()}
          handleCheckIn={handleCheckIn()}
          handleCheckOut={handleCheckOut()}
          adminView={adminView}
          isCheckedIn={isCheckedIn}
        />
      </Box>
    </div>
  );
}

export default HelperInfo;
