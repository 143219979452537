import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import { makeFullNameWithNetIdText } from './commonHelperMethods';

class CheckoutAllStaffDialog extends React.Component {
  state = {
    inputNotificationContent: '', 
    inputNotificationSendAnoymously: false, 
    prevOpen: false, 
    loading: 0, 
  };

  constructor(props) {
    super(props);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }
  
  startLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading + 1, 
    }));
  }

  stopLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading - 1, 
    }));
  }

  fillDefault() {
    const defaultNotificationContent = 
      this.props.roomInfo.label + ': We are unable to meet with you since office hours are over. We apologize for any inconvenience. ';
    this.setState({
      inputNotificationContent: defaultNotificationContent, 
      inputNotificationSendAnoymously: true, 
    });
  }

  componentDidMount() {
    this.fillDefault();
  }

  componentDidUpdate() {
    if (this.state.prevOpen !== this.props.open) {
      this.setState({ prevOpen: this.props.open });
      if (this.props.open) {
        this.fillDefault();
      }
    }
  }

  handleInputChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSwitchChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  handleSubmit() {
    return () => {
      this.startLoading();
      const apiPath = '/api/shifts/check_out_all';
      axios.get(apiPath, { params: {
        } })
        .then(res => {
          this.stopLoading();
          this.props.onClose();
        })
        .catch((error) => {
          this.stopLoading();
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  render() {
    if (!this.props.open) {
      return null;
    }

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{'Checkout all staff'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will check out all staff from the current shift. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleSubmit()}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CheckoutAllStaffDialog;
