import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import { makeFullNameWithNetIdText } from './commonHelperMethods';

class JoinHelpeeDialog extends React.Component {
  state = {
    inputNotificationContent: '', 
    inputNotificationSendAnoymously: false, 
    inputNetId: '', 
    inputSummary: '', 
    inputCategoryId: 0,
    prevOpen: false, 
    loading: 0, 
    new_category: '',
    selected_subcategory: '',
    selected_subcategory_url: '',
  };

  constructor(props) {
    super(props);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }
  
  startLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading + 1, 
    }));
  }

  stopLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading - 1, 
    }));
  }

  fillDefault() {
    const defaultNotificationContent = 
      this.props.roomInfo.label + ': You are put in the queue. ';
    this.setState({
      inputNotificationContent: defaultNotificationContent, 
      inputNotificationSendAnoymously: true, 
      inputNetId: '', 
      inputSummary: '', 
    });
  }

  componentDidMount() {
    this.fillDefault();
  }

  componentDidUpdate() {
    if (this.state.prevOpen !== this.props.open) {
      this.setState({ prevOpen: this.props.open });
      if (this.props.open) {
        this.fillDefault();
      }
    }
  }

  handleInputChange(key) {
    return (event) => {
      const value = event.target.value;
      if (key === 'selected_subcategory') {
        const selectedCategory = this.props.newCategories.find(cat => cat.name === this.state.new_category);
        const selectedPage = selectedCategory?.pages.find(page => page.title === value);
        this.setState({ 
          selected_subcategory: value,
          selected_subcategory_url: selectedPage?.link || ''
        });
      } else {
        this.setState({ [key]: value });
      }
    };
  }

  handleSwitchChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  handleSubmit() {
    return () => {
      this.startLoading();
      const apiPath = '/api/rooms/' + this.props.roomInfo.room_id + '/helpers/join';
      axios.get(apiPath, { params: {
          net_id: this.state.inputNetId, 
          summary: this.state.inputSummary,
          new_category: this.state.selected_subcategory, 
          new_category_url: this.state.selected_subcategory_url,
          notification_content: this.state.inputNotificationContent, 
          notification_send_anonymously: this.state.inputNotificationSendAnoymously, 
        } })
        .then(res => {
          this.props.onUpdateHelpeeList(res.data.data.update_info);
          this.stopLoading();
          this.props.onClose();
        })
        .catch((error) => {
          this.stopLoading();
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  render() {
    if (!this.props.open) {
      return null;
    }

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{'Put a student in the queue'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please let the student know why the student is put in the queue. 
            The notification will be pushed to the student's end. 
            If you do not want to push a notification, please make the text field empty. 
          </DialogContentText>
          <Box mt={1}>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Student NetID</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.inputNetId}
                      onChange={this.handleInputChange('inputNetId')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={this.state.new_category}
                      onChange={this.handleInputChange('new_category')}
                    >
                      {this.props.newCategories.map((category, index) => (
                        <MenuItem key={index} value={category.name}>{category.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Subcategory</InputLabel>
                    <Select
                      value={this.state.selected_subcategory}
                      onChange={this.handleInputChange('selected_subcategory')}
                    >
                      {this.props.newCategories
                        .find(cat => cat.name === this.state.new_category)
                        ?.pages.map((page, index) => (
                          <MenuItem key={index} value={page.title}>{page.title}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Question Summary</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.inputSummary}
                      onChange={this.handleInputChange('inputSummary')}
                      multiline
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Notification</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.inputNotificationContent}
                      onChange={this.handleInputChange('inputNotificationContent')}
                      multiline
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel control={<Switch 
                    checked={this.state.inputNotificationSendAnoymously}
                    onChange={this.handleSwitchChange('inputNotificationSendAnoymously')}
                  />} label="Send Anonymously" />
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleSubmit()}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default JoinHelpeeDialog;
