import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, Typography, Tooltip, Select, MenuItem, Hidden } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { makeFullNameWithNetIdText } from './commonHelperMethods';
import {getUserFullNameWithNetIdText, getUserInfo} from "./userInfo";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import BedtimeOffIcon from '@mui/icons-material/BedtimeOff';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import HelpeeSelfRemoveDialog from './HelpeeSelfRemoveDialog';

const pluralizeStudentsInFrontText = (e) =>
  e === 0 ? "You're next!" : e === 1 ? "There is 1 student in front of you." :
      `There are ${e} students in front of you.`;

class HelpeeInfo extends React.Component {
  state = {
    input_summary: '', 
    new_category: '',
    selected_subcategory: '',
    selected_subcategory_url: '',
    helpeeSelfRemoveDialogOpen: false, 
  };

  componentDidMount() {
    this.props.onLoadHelpeeInfo();
  }

  handleInputChange(key) {
    return (event) => {
      const value = event.target.value;
      if (key === 'selected_subcategory') {
        const selectedCategory = this.props.newCategories.find(cat => cat.name === this.state.new_category);
        const selectedPage = selectedCategory?.pages.find(page => page.title === value);
        this.setState({ 
          selected_subcategory: value,
          selected_subcategory_url: selectedPage?.link || ''
        });
      } else {
        this.setState({ [key]: value });
      }
    };
  }
  
  checkZoomAuthorized() {
    const apiPath = '/api/zoom/check_authorized';
    axios.get(apiPath)
      .then(res => {
        console.log(res.data.data);
        if (!res.data.data.zoom_authorized) {
          window.open('/zoom_oauth2');
        }
      })
      .catch((error) => {
        this.props.onApiThrow(apiPath, error);
      });
  }

  handleJoin() {
    return () => {
      this.props.onStartLoading();
      const apiPath = '/api/rooms/' + this.props.roomInfo.room_id + '/join';
      axios.get(apiPath, { params: {
          summary: this.state.input_summary, 
          new_category: this.state.selected_subcategory,
          new_category_url: this.state.selected_subcategory_url
        } })
        .then(res => {
          this.props.onUpdateHelpeeInfo(res.data.data.helpee);
          this.checkZoomAuthorized();
          this.props.onStopLoading();
        })
        .catch((error) => {
          this.props.onStopLoading();
          this.props.onApiThrow(apiPath, error);
        });
    }
  }

  handleRemove() {
    return () => {
      this.setState({ helpeeSelfRemoveDialogOpen: true });
    }
  }

  handleEnterConference() {
    return () => {
      this.props.onStartLoading();
      const apiPath = '/api/rooms/' + this.props.roomInfo.room_id + '/get_conference_link';
      axios.get(apiPath, { params: {
        } })
        .then(res => {
          window.open(res.data.data.conference_link);
          this.props.onStopLoading();
        })
        .catch((error) => {
          this.props.onStopLoading();
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  render() {
    if (Object.keys(this.props.roomInfo).length === 0 || 
        Object.keys(this.props.helpeeInfo).length === 0) {
      return null;
    }

    return (
      <div>
        <HelpeeSelfRemoveDialog
          onApiThrow={this.props.onApiThrow}
          open={this.state.helpeeSelfRemoveDialogOpen}
          roomId={this.props.roomInfo.room_id}
          onClose={() => { this.setState({ helpeeSelfRemoveDialogOpen: false }); }}
          onUpdateHelpeeInfo={this.props.onUpdateHelpeeInfo}
        />
        { this.props.helpeeInfo.status === 'helping' ? (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} align="center">
              <Typography variant="h5" mb={3} sx={{fontWeight: "bold", display: 'flex', color: 'green',
                alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}}>
                <CheckCircleIcon sx={{paddingRight: '5px'}} />
                { `We're helping you now, ${getUserInfo().first_name }.`}
              </Typography>

              <div style={{display: 'inline-block', border: '1px solid #dee2e6', padding: '1.5rem 2rem', borderRadius: '0.5rem'}}>
                <Typography mb={2} variant="body1" style={{fontSize: '0.8rem', color: 'gray'}}>
                  Assigned Staff
                </Typography>

                <img src={this.props.helpeeInfo.helper_user.avatar ?? "https://cs128.org/images/cs128.svg"}
                     style={{borderRadius: '50%', width: '80px', height: '80px'}} />
                <Typography variant="h6" mt={1} sx={{fontWeight: "normal"}}>
                  {this.props.helpeeInfo.helper_user.first_name + ' ' + this.props.helpeeInfo.helper_user.last_name}
                </Typography>
                <Typography variant="body1">
                  {this.props.helpeeInfo.helper_user.net_id}
                </Typography>
              </div>

              <Typography mt={2} variant="body1">
                { 'Joined: ' + new Date(this.props.helpeeInfo.join_time).toLocaleString() }
              </Typography>
              <Typography variant="body1">
                { 'Question: ' + this.props.helpeeInfo.summary }
              </Typography>
            </Grid>
            {this.props.helpeeInfo.location ? (
              <Grid item xs={12} align="center">
                <Typography variant="h5">
                  Location: {
                    this.props.helpeeInfo.location
                  }
                </Typography>
              </Grid>
            ) : null}
            <Grid mt={3} container justifyContent="center" alignItems="center" style={{gap: 15}}>
              <Tooltip title={this.props.helpeeInfo.self_remove_permit ?
                  "You can remove yourself if our staff forgot to remove you." :
                  "Our staff will remove you once they have finished helping you."
              } arrow followCursor={true}>
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={!this.props.helpeeInfo.self_remove_permit}
                    onClick={this.handleRemove()}
                  >
                    Leave queue
                  </Button>
                </span>
              </Tooltip>
              {this.props.helpeeInfo.conference_enabled ? (
                <Button
                  onClick={this.handleEnterConference()}
                  variant="contained"
                  disabled={!this.props.helpeeInfo.conference_enabled}
                >
                  Join meeting
                </Button>
              ) : null}
            </Grid>
          </Grid>
        ) : this.props.helpeeInfo.status === 'waiting' || this.props.helpeeInfo.status === 'assigning' ? (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} align="center">
              <Typography variant="h5" mb={2} sx={{fontWeight: "bold", display: 'flex',
                alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="primary">
                <EmojiEmotionsIcon sx={{paddingRight: '5px'}} />
                { getUserInfo().first_name + ', you are on the queue!' }
              </Typography>
              <Typography variant="body1">
                { 'Joined: ' + new Date(this.props.helpeeInfo.join_time).toLocaleString() }
              </Typography>
              <Typography variant="body1">
                { 'Category: ' }
                <a href={this.props.helpeeInfo.new_category_url} target="_blank" rel="noopener noreferrer">
                  {this.props.helpeeInfo.new_category}
                </a>
              </Typography>
              <Typography variant="body1">
                { 'Question: ' + this.props.helpeeInfo.summary }
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography variant="h5">
                {
                  this.props.helpeeInfo.helpee_num_in_front >= 0 ? (
                   pluralizeStudentsInFrontText(this.props.helpeeInfo.helpee_num_in_front)
                  ) : ''
                }
              </Typography>
            </Grid>
            {this.props.helpeeInfo.location ? (
              <Grid item xs={12} align="center">
                <Typography variant="h5">
                  Location: {
                    this.props.helpeeInfo.location
                  }
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} align="center">
              {this.props.helpeeInfo.conference_enabled ? (
                <Typography variant="h6">
                  Please wait in the meeting room. 
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} align="center">
              <Grid mt={3} container justifyContent="center" alignItems="center" style={{gap: 15}}>
                <Button 
                  variant="contained" 
                  color="error"
                  disabled={!this.props.helpeeInfo.self_remove_permit}
                  onClick={this.handleRemove()}
                >
                  Leave queue
                </Button>
                {this.props.helpeeInfo.conference_enabled ? (
                  <Button
                    onClick={this.handleEnterConference()}
                    variant="contained"
                    disabled={!this.props.helpeeInfo.conference_enabled}
                  >
                    Join meeting
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        ) : this.props.roomInfo.status === 'open' ? (
          <Grid container spacing={4} mt={1}>
            <Grid item xs={12}>
              <Typography variant="h5">
                How can we help you?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={this.state.new_category}
                  onChange={this.handleInputChange('new_category')}
                >
                  {Object.values(this.props.newCategories).map((category, index) => (
                    <MenuItem key={index} value={category.name}>{category.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              {this.state.new_category && (
                <FormControl fullWidth>
                  <InputLabel>Subcategory</InputLabel>
                  <Select
                    value={this.state.selected_subcategory}
                    onChange={this.handleInputChange('selected_subcategory')}
                  >
                    {this.props.newCategories
                      .find(cat => cat.name === this.state.new_category)
                      ?.pages.map((page, index) => (
                        <MenuItem key={index} value={page.title}>{page.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <div style={{height: '1rem'}} />
              <FormControl fullWidth>
                <InputLabel>Question Summary</InputLabel>
                <Input
                  value={this.state.input_summary}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^\x00-\x7F]/g, "");
                    this.handleInputChange('input_summary')(e);
                  }}
                />
                <FormHelperText>
                  Please be specific about your question. 
                  We will remove you from the queue if you put gibberish.
                </FormHelperText>
                <FormHelperText>
                  <span>
                    {this.state.input_summary.length}
                  </span>
               </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} align="center">
              <Button 
                variant="contained" 
                onClick={this.handleJoin()}
              >
                Put me on the queue
              </Button>
            </Grid>
          </Grid>
        ) : this.props.roomInfo.status_text === 'not on duty' ? (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} align="center">
                <Typography variant="h5" mb={2} sx={{fontWeight: "bold", display: 'flex',
                  alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="secondary">
                  <BedtimeOffIcon sx={{paddingRight: '5px'}} />
                  { 'Sorry, we\'re closed.' }
                </Typography>
                <Typography variant="body1" sx={{lineHeight: '1.7'}}>
                  We're closed and no staff member is on duty. <br/>
                  Please check course calendar for our hours.
                </Typography>
              </Grid>
            </Grid>
        ) : this.props.roomInfo.status_text === 'high traffic' ? (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} align="center">
                <Typography variant="h5" mb={2} sx={{fontWeight: "bold", display: 'flex',
                  alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="primary">
                  <AcUnitIcon sx={{paddingRight: '7px'}} />
                  { "The queue is temporarily frozen." }
                </Typography>
                <Typography variant="body1" sx={{lineHeight: '1.7'}}>
                  We apologize. We are currently overloaded and have<br/>
                  temporarily frozen the queue. Please check back later.
                </Typography>
              </Grid>
            </Grid>
        ) : (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} align="center">
                <Typography variant="h5" mb={2} sx={{fontWeight: "bold", display: 'flex',
                  alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}} color="error">
                  <DoNotDisturbOnIcon sx={{paddingRight: '7px'}} />
                  { "This queue is closed." }
                </Typography>
                <Typography variant="body1" sx={{lineHeight: '1.7'}}>
                  Reason: {this.props.roomInfo.status_text}
                </Typography>
              </Grid>
            </Grid>
        )}
      </div>
    );
  }
}

export default HelpeeInfo;
