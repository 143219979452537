import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import { getUserFullNameWithNetIdText } from './userInfo';
import { makeFullNameWithNetIdText } from './commonHelperMethods';

class HelpProposalDialog extends React.Component {
  state = {
    inputNotificationContent: '', 
    prevOpen: false, 
    loading: 0, 
    inputLocation: '',
    timeRemaining: 0,
  };

  constructor(props) {
    super(props);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }
  
  startLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading + 1, 
    }));
  }

  stopLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading - 1, 
    }));
  }

  fillDefault() {
    const defaultNotificationContent = 
      this.props.roomInfo.label + ': It\'s your turn! Please join the meeting to meet with our staff. ';
    this.setState({
      inputNotificationContent: defaultNotificationContent, 
      inputNotificationSendAnoymously: true, 
      inputLocation: ''
    });
  }

  componentDidMount() {
    this.fillDefault();
    this.setupTimeout();
    this.startTimer();
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.proposal !== prevProps.proposal) {
      this.setupTimeout();
      this.startTimer();
    }
    if (this.state.prevOpen !== this.props.open) {
      this.setState({ prevOpen: this.props.open });
      if (this.props.open) {
        this.fillDefault();
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  setupTimeout() {
    if (this.props.proposal) {
      const timeoutAt = new Date(this.props.proposal.timeout_at).getTime();
      const now = new Date().getTime();
      const timeRemaining = timeoutAt - now;

      this.setState({ timeRemaining });

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      if (timeRemaining > 0) {
        this.timeout = setTimeout(() => {
          this.stopLoading();
          this.props.onClose();
        }, timeRemaining);
      } else {
        this.stopLoading();
        this.props.onClose();
      }
    }
  }

  startTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      this.setState((prevState) => {
        const newTimeRemaining = prevState.timeRemaining - 1000;
        if (newTimeRemaining <= 0) {
          clearInterval(this.interval);
          this.stopLoading();
          this.props.onClose();
        }
        return { timeRemaining: newTimeRemaining };
      });
    }, 1000);
  }

  formatTime(ms) {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  handleInputChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSwitchChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  handleSubmit() {
    return () => {
      this.startLoading();
      const apiPath = '/api/rooms/' + this.props.roomInfo.room_id + '/helpers/accept_proposal';
      axios.get(apiPath, { params: {
          helpee_id: this.props.helpee.helpee_id, 
          notification_content: this.state.inputNotificationContent, 
          notification_send_anonymously: false, 
          location: this.state.inputLocation,
          proposal_id: this.props.proposal.id
        } })
        .then(res => {
          this.props.onUpdateHelpeeList(res.data.data.update_info);
          this.stopLoading();
          this.props.onClose();
        })
        .catch((error) => {
          this.stopLoading();
          this.props.onClose();
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  handleDecline() {
    return () => {
      this.startLoading();
      const apiPath = '/api/rooms/' + this.props.roomInfo.room_id + '/helpers/reject_proposal';
      axios.get(apiPath, { params: {
          helpee_id: this.props.helpee.helpee_id, 
          notification_content: this.state.inputNotificationContent, 
          notification_send_anonymously: this.state.inputNotificationSendAnoymously, 
          location: this.state.inputLocation,
          proposal_id: this.props.proposal.id
        } })
        .then(res => {
          this.stopLoading();
          this.props.onClose();
        })
        .catch((error) => {
          this.stopLoading();
          this.props.onClose();
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.handleSubmit()();
    }
  }

  render() {
    const timeRemainingStyle = {
      fontSize: '1.75em',
      color: this.state.timeRemaining < 10000 ? 'white' : 'black',
      fontWeight: 'bold',
      background: this.state.timeRemaining < 10000 ? 'red' : '#f0f0f0',
      padding: '0.55em',
      borderRadius: '0.55em',
      textAlign: 'center',
      marginTop: '0.625em',
      boxShadow: '0 0.25em 0.5em rgba(0, 0, 0, 0.1)',
    };

    return (
      <Dialog 
        open={this.props.open} 
        onClose={(event, reason) => {
          if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
            this.props.onClose();
          }
        }}
        disableEscapeKeyDown={true}
      >
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{'Assigned to Help: ' + makeFullNameWithNetIdText(this.props.helpee.helpee_user)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <a href={this.props.helpee.new_category_url} target="_blank" rel="noopener noreferrer">
              {this.props.helpee.new_category}
            </a>
            <Typography variant="body1" style={{ 
              fontSize: '1.25em', 
              color: 'black', 
              marginTop: '0.5em'
            }}>
              <strong>Summary:</strong> {this.props.helpee.summary}
            </Typography>
          </DialogContentText>
          <Typography variant="body2" color="textSecondary" style={timeRemainingStyle}>
            {Math.ceil(this.state.timeRemaining / 1000)} seconds remaining
          </Typography>
        </DialogContent>
        <DialogContent>
          <Box mt={1}>
            <FormGroup>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Location</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.inputLocation}
                      onChange={this.handleInputChange('inputLocation')}
                      multiline
                    />
                  </FormControl>
                </Grid>

                {this.props.isOutOfOrder ?
                  <Grid item xs={12}>
                    <Typography variant="h6" color="error">
                      Note: This is not the next person in queue.<br/>
                      You should not start helping this person unless you have a good reason.
                    </Typography>
                  </Grid>
                : null}
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
      
        <DialogActions>
          <Button onClick={this.handleDecline()}>Decline</Button>
          <Button 
            variant="contained" 
            color="success" 
            size="large" 
            onClick={this.handleSubmit()}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HelpProposalDialog;
