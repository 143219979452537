import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, FormControl, FormHelperText, Grid, Input, InputLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import RemoveHelpeeDialog from './RemoveHelpeeDialog';
import StartHelpHelpeeDialog from './StartHelpHelpeeDialog';
import { makeFullNameWithNetIdText } from './commonHelperMethods';
import CancelHelpHelpeeDialog from './CancelHelpHelpeeDialog';
import JoinHelpeeDialog from './JoinHelpeeDialog';
import RoomUpdateDialog from './RoomUpdateDialog';
import { getUserNetId, hasAdminAuth } from './userInfo';
import { SentimentSatisfiedOutlined } from '@mui/icons-material';

function HelperHistory(props) {
  const [inited, setInited] = useState(false);
  const [helpHistories, setHelpHistories] = useState([]);
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    if (!inited && props.roomId != null) {
      setInited(true);
      setLoading((prev) => (prev + 1));
      const apiPath = '/api/rooms/' + props.roomId + '/helpers/help_history';
      axios.get(apiPath)
        .then(res => {
          setHelpHistories(res.data.data.reverse());
          setLoading((prev) => (prev - 1));
        })
        .catch((error) => {
          setLoading((prev) => (prev - 1));
          props.onApiThrow(apiPath, error);
        });
    }
  });

  const openNotes = (netId) => {
    window.open('https://cs128.org/howdy/student/resolve/' + 
      netId + '/notes');
  };

  const stopHelpTypeMap = (stopHelpType) => {
    let result = null;
    if (stopHelpType === 'helpee_remove') {
      result = 'Self Removed';
    } else if (stopHelpType === 'helper_finish') {
      result = 'Finish';
    } else if (stopHelpType === 'helper_cancel') {
      result = 'Unhelp';
    } else if (stopHelpType === 'helper_remove') {
      result = 'Miss';
    } else if (stopHelpType === 'assistant_stop') {
      result = 'Finished Assisting'
    } else {
      result = 'Unknown (' + stopHelpType + ')';
    }
    return result;
  };

  const tableBody = helpHistories.map((helpHistory, i) => (
    <TableRow key={i}>
      <TableCell>
        <Typography 
          onClick={() => { openNotes(helpHistory.helpee_user.net_id); }}
          sx={{ cursor: "pointer" }}
          color="primary"
        >
          {makeFullNameWithNetIdText(helpHistory.helpee_user)}
        </Typography>
      </TableCell>
      <TableCell>
        {new Date(helpHistory.helpee_join_time).toLocaleString()}
      </TableCell>
      <TableCell>
        {new Date(helpHistory.start_help_time).toLocaleString()}
      </TableCell>
      <TableCell>
        {new Date(helpHistory.stop_help_time).toLocaleString()}
      </TableCell>
      <TableCell>
        {stopHelpTypeMap(helpHistory.stop_help_type)}
      </TableCell>
      <TableCell>
        {helpHistory.new_category ? <a href={helpHistory.new_category_url} target="_blank" rel="noopener noreferrer">
          {helpHistory.new_category}
        </a> : helpHistory.category}
      </TableCell>
      <TableCell>
        {helpHistory.summary}
      </TableCell>
    </TableRow>
  ));

  return (
    <React.Fragment>
      <Backdrop 
        sx={{ color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
        }}
        open={loading > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table>
          <colgroup>
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="8%" />
            <col width="10%" />
            <col width="30%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              <TableCell>Place Time</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>Stop Time</TableCell>
              <TableCell>Stop Type</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Question Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBody}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default HelperHistory;
