import React from 'react';
import axios from 'axios';
import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, Stack, Switch, Typography } from '@mui/material';

const STATUS_TEXT_HIGH_TRAFFIC = 'high traffic';
const STATUS_TEXT_NOT_ON_DUTY = 'not on duty';

class RoomUpdateDialog extends React.Component {
  state = { loading: 0, ready: false, data: {} };

  componentDidUpdate() {
    if (this.props.open && !this.state.ready && this.state.loading <= 0) {
      this.setState((prevState) => ({
        loading: prevState.loading + 1, 
      }));
      const apiPath = '/api/rooms/' + this.props.roomId + '/room_info';
      axios.get(apiPath)
        .then(res => {
          const data = {
            label: res.data.data.label, 
            description: res.data.data.description, 
            status: res.data.data.status, 
            status_text: res.data.data.status_text, 
            announcement: res.data.data.announcement, 
            default_conference_enabled: res.data.data.default_conference_enabled,
            auto_assignment_enabled: res.data.data.auto_assignment_enabled,
          };
          this.setState((prevState) => ({
            loading: prevState.loading - 1, 
          }));
          this.setState({ data: data, ready: true });
        })
        .catch((error) => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1, 
          }));
          this.props.onClose();
          this.props.onApiThrow(apiPath, error);
        });
    }
  }

  handleInputChange(key) {
    return (event) => {
      this.setState((prevState) => {
        prevState.data[key] = event.target.value;
        return { data: prevState.data };
      });
    };
  }

  handleSwitchChange(key) {
    return (event) => {
      this.setState((prevState) => {
        prevState.data[key] = event.target.checked;
        return { data: prevState.data };
      });
    };
  }

  handleClose() {
    return () => {
      this.setState({ ready: false });
      this.props.onClose();
    }
  }

  handleClickSubmit() {
    return () => {
      this.setState((prevState) => ({
        loading: prevState.loading + 1
      }));
      const apiPath = '/api/rooms/' + this.props.roomId + '/update';
      axios.get(apiPath, 
        { params: this.state.data })
        .then(res => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1
          }));
          this.props.onUpdateRoomInfo(res.data.data.room);
          (this.handleClose())()
        })
        .catch((error) => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1, 
          }));
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  handleQuickFillStatusOpen() {
    return () => {
      this.setState((prevState) => {
        prevState.data.status = 'open';
        prevState.data.status_text = '';
        return { data: prevState.data };
      });
    }
  }

  handleQuickFillStatusHighTraffic() {
    return () => {
      this.setState((prevState) => {
        prevState.data.status = 'closed';
        prevState.data.status_text = STATUS_TEXT_HIGH_TRAFFIC;
        return { data: prevState.data };
      });
    }
  }

  handleQuickFillStatusNotOnDuty() {
    return () => {
      this.setState((prevState) => {
        prevState.data.status = 'closed';
        prevState.data.status_text = STATUS_TEXT_NOT_ON_DUTY;
        return { data: prevState.data };
      });
    }
  }

  render() {
    if (!this.props.open) {
      return null;
    }

    const form = this.state.ready ? (
      <div>
        <DialogContent dividers>
          <Grid container 
            spacing={3}>
            { this.props.adminView ? (
              <React.Fragment>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Label</InputLabel>
                    <Input
                      value={this.state.data.label}
                      onChange={this.handleInputChange('label')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                  <InputLabel>Description</InputLabel>
                  <Input
                    value={this.state.data.description}
                    onChange={this.handleInputChange('description')}
                    multiline
                  />
                  </FormControl>
                </Grid>
                <Grid item xs={4}
                  display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="body2" color="text.secondary">
                    Status
                  </Typography> 
                </Grid>
                <Grid item xs={8}
                  display="flex" alignItems="center" justifyContent="center">
                  <RadioGroup row
                    value={this.state.data.status}
                    onChange={this.handleInputChange('status')}>
                    <FormControlLabel value="open" control={<Radio />} label="Open" />
                    <FormControlLabel value="closed" control={<Radio />} label="Closed" />
                    <FormControlLabel value="hidden" control={<Radio />} label="Hidden" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Status Text</InputLabel>
                    <Input
                      value={this.state.data.status_text}
                      onChange={this.handleInputChange('status_text')}
                    />
                  </FormControl>
                </Grid>
              </React.Fragment>
            ) : null }
            <Grid item xs={this.props.adminView ? 4 : 0}
              display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body2" color="text.secondary">
                { this.props.adminView ? 'Status Quick Update' : null }
              </Typography> 
            </Grid>
            <Grid item xs
              display="flex" alignItems="center" justifyContent="center">
              <ButtonGroup size="small">
                <Button onClick={this.handleQuickFillStatusOpen()}
                  color={this.state.data.status === 'open' && 
                  this.state.data.status_text === '' ? 'primary' : 'secondary'}
                  sx={{
                    fontWeight: this.state.data.status === 'open' &&
                      this.state.data.status_text === '' ?
                      'bold' : 'normal', 
                  }}
                >
                  open
                </Button>
                <Button onClick={this.handleQuickFillStatusHighTraffic()}
                  color={this.state.data.status === 'closed' && 
                  this.state.data.status_text === STATUS_TEXT_HIGH_TRAFFIC ? 
                  'primary' : 'secondary'}
                  sx={{
                    fontWeight: this.state.data.status === 'closed' && 
                      this.state.data.status_text === STATUS_TEXT_HIGH_TRAFFIC ? 
                      'bold' : 'normal', 
                  }}
                >
                  frozen
                </Button>
                <Button onClick={this.handleQuickFillStatusNotOnDuty()}
                  color={this.state.data.status === 'closed' && 
                  this.state.data.status_text === STATUS_TEXT_NOT_ON_DUTY ? 
                  'primary' : 'secondary'}
                  sx={{
                    fontWeight: this.state.data.status === 'closed' && 
                      this.state.data.status_text === STATUS_TEXT_NOT_ON_DUTY ? 
                      'bold' : 'normal', 
                  }}
                >
                  closed
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Announcement</InputLabel>
                <Input
                  value={this.state.data.announcement}
                  onChange={this.handleInputChange('announcement')}
                  multiline
                />
                <FormHelperText>
                  Markdown enabled
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControlLabel control={<Switch 
                checked={this.state.data.default_conference_enabled}
                onChange={this.handleSwitchChange('default_conference_enabled')}
              />} label="Zoom Enabled" />
            </Grid>
            { this.props.adminView ? (
              <Grid item xs>
                <FormControlLabel control={<Switch 
                  checked={this.state.data.auto_assignment_enabled}
                  onChange={this.handleSwitchChange('auto_assignment_enabled')}
                />} label="Auto Assignment Enabled" />
              </Grid>
            ) : null }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose()}>Cancel</Button>
          <Button onClick={this.handleClickSubmit()}>Submit</Button>
        </DialogActions>
      </div>
    ) : null;

    return (
      <Dialog 
        open={this.props.open}
        onClose={this.handleClose()}
        scroll="paper"
      >
        <DialogTitle>Room Setting</DialogTitle>
        <Backdrop 
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        { form }
      </Dialog>
    );
  }
}

export default RoomUpdateDialog;
