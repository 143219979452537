import React, { useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';

import {useLocation, useParams} from 'react-router-dom';
import { Backdrop, Button, ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';
import { Route, Routes, useNavigate, NavLink } from "react-router-dom";

import RoomInfo from "./RoomInfo";
import HelpeeInfo from './HelpeeInfo';
import HelperInfo from './HelperInfo';
import { Box } from '@mui/system';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { observer } from 'mobx-react'
import { getUserNetId, hasStaffAuth } from './userInfo';
import HelperHistory from './HelperHistory';
import StudentHistory from './StudentHistory';
import { beep } from './audios';
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const RoomComponent = observer((props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      updateRoomInfo: updateRoomInfo,
      updateHelpeeInfo: updateHelpeeInfo,
      updateHelpeeList: updateHelpeeList,
      updateShiftHelperList: updateShiftHelperList,
      updateProposal: updateProposal,
      loadCurrentProposal: loadCurrentProposal,
      loadHelpeeList: loadHelpeeList,
      loadHelpeeInfo: loadHelpeeInfo,
      loadShiftHelperList: loadShiftHelperList,
      loadNewCategories: loadNewCategories,
    };
  });

  const [loading, setLoading] = useState(0);
  const [roomInfo, setRoomInfo] = useState({});
  const [helpeeInfo, setHelpeeInfo] = useState({});
  const [helpeeList, setHelpeeList] = useState([]);
  const [shiftHelperList, setShiftHelperList] = useState([]);
  const [studentHistory, setStudentHistory] = useState([]);
  const [proposal, setProposal] = useState(null);
  const [newCategories, setNewCategories] = useState([]);

  const startLoading = () => {
    setLoading((prev) => (prev + 1));
  };

  const stopLoading = () => {
    setLoading((prev) => (prev - 1));
  };

  const navigate = useNavigate();
  const location = useLocation();

  const currentView = () => location.pathname.includes("staff/help_history") ? "history" :
      location.pathname.includes("staff") ? "staff" : "student";

  const currentViewStudent = () => location.pathname.includes("student/help_history") ? "history" : "student";

  const openStudentView = () => {
    navigate('/rooms/' + props.roomId);
  };

  const openStaffView = () => {
    navigate('/rooms/' + props.roomId + '/staff');
  };

  const openHelpHistory = () => {
    navigate('/rooms/' + props.roomId + '/staff/help_history');
  };

  const openStudentHelpHistory = () => {
    navigate('/rooms/' + props.roomId + '/student/help_history');
  };

  const navigateStudentView = (_, e) => String(e) === "history" ? openStudentHelpHistory() :
      openStudentView();

  const navigateStaffView = (_, e) => String(e) === "history" ? openHelpHistory() :
        String(e) === "staff" ? openStaffView() : openStudentView();

  const loadRoomInfo = () => {
    startLoading();
    const apiPath = '/api/rooms/' + props.roomId + '/room_info';
    axios.get(apiPath)
      .then(res => {
        setRoomInfo(res.data.data);
        stopLoading();
      })
      .catch((error) => {
        stopLoading();
        props.onApiThrow(apiPath, error);
      });
  };

  const updateRoomInfo = (updateInfo) => {
    if (updateInfo.deleted) {
      props.onGoRoomList();
      return;
    }
    var newInfo = { ...roomInfo };
    Object.keys(updateInfo).forEach((key) => {
      newInfo[key] = updateInfo[key];
    });
    setRoomInfo(newInfo);
  };

  const loadHelpeeInfo = async ({ signal } = {}) => {
    const apiPath = '/api/rooms/' + props.roomId + '/helpee_info';
    try {
      startLoading();
      const response = await axios.get(apiPath, { signal });
      setHelpeeInfo(response.data.data);
      stopLoading();
    } catch (error) {
      stopLoading();
      if (!axios.isCancel(error)) {
        props.onApiThrow(apiPath, error);
      }
    } 
  };

  const updateHelpeeInfo = (updateInfo) => {
    if (updateInfo.status === 'helping' &&
      updateInfo.status !== helpeeInfo.status) {
      beep();
    }

    var newInfo = { ...helpeeInfo };
    Object.keys(updateInfo).forEach((key) => {
      newInfo[key] = updateInfo[key];
    });
    setHelpeeInfo(newInfo);
  };

  const loadHelpeeList = async ({ signal } = {}) => {
    const apiPath = '/api/rooms/' + props.roomId + '/helpers/helpee_list';
    try {
      startLoading();
      const response = await axios.get(apiPath, { signal });
      setHelpeeList(response.data.data);
      stopLoading();
    } catch (error) {
      stopLoading();
      if (!axios.isCancel(error)) {
        props.onApiThrow(apiPath, error);
      }
    }
  };

  const loadShiftHelperList = async ({ signal } = {}) => {
    const apiPath = '/api/shifts/helper_list';
    try {
      startLoading();
      const response = await axios.get(apiPath, { signal });
      setShiftHelperList(response.data.data);
      stopLoading();
    } catch (error) {
      stopLoading();
      if (!axios.isCancel(error)) {
        props.onApiThrow(apiPath, error);
      }
    }
  };

  const loadStudentHelpHistory = () => {
    startLoading();
    const apiPath = '/api/rooms/' + props.roomId + '/student/helpee_list';
    axios.get(apiPath)
        .then(res => {
          setStudentHistory(res.data.data);
          stopLoading();
        })
        .catch((error) => {
          stopLoading();
          props.onApiThrow(apiPath, error);
        });
  }

  const updateHelpeeList = (helpeeListUpdateInfo) => {
    let newHelpeeList = [...helpeeList];
    for (var j = 0; j < helpeeListUpdateInfo.length; ++j) {
      if (helpeeListUpdateInfo[j].status === 'not_joined') {
        // remove
        newHelpeeList = newHelpeeList.filter((item) => item.helpee_id !== helpeeListUpdateInfo[j].helpee_id);
      } else {
        // update
        let updated = false;
        for (var i = 0; i < newHelpeeList.length; ++i) {
          if (newHelpeeList[i].helpee_id === helpeeListUpdateInfo[j].helpee_id) {
            newHelpeeList[i] = helpeeListUpdateInfo[j];
            updated = true;
            break;
          }
        }
        if (!updated) {
          // insert
          newHelpeeList.push(helpeeListUpdateInfo[j]);
          newHelpeeList.sort((helpeeA, helpeeB) => {
            return helpeeA.order - helpeeB.order;
          });
        }
      }
    }

    setHelpeeList(newHelpeeList);
  };

  const updateShiftHelperList = (newHelperList) => {
    setShiftHelperList(newHelperList);
  };
  
  const updateProposal = (newProposal) => {
    setProposal(newProposal);
  };

  const loadCurrentProposal = async ({ signal } = {}) => {
    const apiPath = `/api/rooms/${props.roomId}/helpers/current_proposal/${getUserNetId()}`;
    try {
      startLoading();
      const response = await axios.get(apiPath, { signal });
      if (response.data.data) {
        setProposal(response.data.data);
      } else {
        setProposal(null);
      }
      stopLoading();
    } catch (error) {
      stopLoading();
    }
  };

  const loadNewCategories = async ({ signal } = {}) => {
    const apiPath = '/api/rooms/new_categories';
    try {
      startLoading();
      const response = await axios.get(apiPath, { signal });
      setNewCategories(response.data.data.new_categories.categories);
      console.log(response);
      stopLoading();
    } catch (error) {
      stopLoading();
      if (!axios.isCancel(error)) {
        props.onApiThrow(apiPath, error);
      }
    }
  };

  return (
    <div>
      <Backdrop 
        sx={{ color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
        }}
        open={loading > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Button variant="text" onClick={props.onGoRoomList} startIcon={<KeyboardArrowLeftIcon />}>All Queues</Button>
      <RoomInfo
        roomInfo={roomInfo}
        onLoadRoomInfo={loadRoomInfo}
        onUpdateRoomInfo={updateRoomInfo}
        onGoRoomList={props.onGoRoomList}
      />
        { hasStaffAuth() ? (
            <div style={{float: 'right', paddingTop: '1rem'}}>
              <ToggleButtonGroup
                color="primary"
                value={currentView()}
                exclusive
                onChange={navigateStaffView}>
                <ToggleButton value="student">Student View</ToggleButton>
                <ToggleButton value="staff">Staff View</ToggleButton>
                <ToggleButton value="history">Help History</ToggleButton>
              </ToggleButtonGroup>
            </div>
        ) :
        (
            <div style={{float: 'right', paddingTop: '1rem'}}>
              <ToggleButtonGroup
                  color="primary"
                  value={currentViewStudent()}
                  exclusive
                  onChange={navigateStudentView}>
                <ToggleButton value="student">Queue</ToggleButton>
                <ToggleButton value="history">History</ToggleButton>
              </ToggleButtonGroup>
            </div>
        )
        }
      <Routes>
        <Route path="/*" element={
          <HelpeeInfo 
            onApiThrow={props.onApiThrow}
            onStartLoading={startLoading}
            onStopLoading={stopLoading}
            roomInfo={roomInfo}
            helpeeInfo={helpeeInfo}
            onLoadRoomInfo={loadRoomInfo}
            onUpdateHelpeeInfo={updateHelpeeInfo}
            onLoadHelpeeInfo={loadHelpeeInfo}
            onLoadHelpeeList={loadHelpeeList}
            newCategories={newCategories}
          />
        } />
        <Route path="/staff" element={
          <HelperInfo
            onApiThrow={props.onApiThrow}
            onStartLoading={startLoading}
            onStopLoading={stopLoading}
            roomInfo={roomInfo}
            helpeeList={helpeeList}
            onLoadHelpeeList={loadHelpeeList}
            onUpdateHelpeeList={updateHelpeeList}
            shiftHelperList={shiftHelperList}
            onLoadHelpeeInfo={loadHelpeeInfo}
            onLoadShiftHelperList={loadShiftHelperList}
            onUpdateShiftHelperList={updateShiftHelperList}
            onLoadCurrentProposal={loadCurrentProposal}
            onLoadRoomInfo={loadRoomInfo}
            onUpdateRoomInfo={updateRoomInfo}
            proposal={proposal}
            setProposal={setProposal}
            onClose={() => setProposal(null)}
            onUpdateProposal={updateProposal}
            newCategories={newCategories}
          />
        } />
        <Route path="/staff/help_history" element={
          <HelperHistory
            onApiThrow={props.onApiThrow}
            roomId={roomInfo.room_id}
            helpeeList={helpeeList}
          />
        } />
        <Route path="/student/help_history" element={
          <StudentHistory
              onApiThrow={props.onApiThrow}
              onStartLoading={startLoading}
              onStopLoading={stopLoading}
              roomId={roomInfo.room_id}
              studentHistory={studentHistory}
              onLoadStudentHistoryList={loadStudentHelpHistory}
          />
        } />

      </Routes>
    </div>
  );
});

function Room(props) {
  const { roomId } = useParams();
  let roomComponentRef = React.createRef();
  const abortControllerRef = React.createRef();
  useEffect(() => {
    roomComponentRef.current.loadNewCategories();
  }, []);
  useEffect(() => {
    const handleFocus = () => {
      if (roomComponentRef.current) {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        if (hasStaffAuth()) {
          roomComponentRef.current.loadHelpeeList({ signal });
          roomComponentRef.current.loadHelpeeInfo({ signal });
          roomComponentRef.current.loadShiftHelperList({ signal });
          roomComponentRef.current.loadCurrentProposal({ signal });
        }

      }
    };

    const handleBlur = () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleRoomUpdateOnReceived = () => {
    return (message) => {
      roomComponentRef.current.updateRoomInfo(message);
    };
  };

  const handleHelpeeUpdateOnReceived = () => {
    return (message) => {
      roomComponentRef.current.updateHelpeeInfo(message);
    };
  };

  const handleHelperUpdateOnReceived = () => {
    return (message) => {
      roomComponentRef.current.updateHelpeeList(message);
    };
  };

  const handleShiftHelperUpdateOnReceived = () => {
    return (message) => {
      roomComponentRef.current.updateShiftHelperList(message);
    };
  };

  const handleProposalOnReceived = () => {
    return (message) => {
      if (message.proposal.helper_net_id ===  getUserNetId() && message.proposal.status === 'pending') {
        roomComponentRef.current.updateProposal(message.proposal);
      } else {
        roomComponentRef.current.updateProposal(null);
      }
    };
  };

  return (
    <div>
      <ActionCableConsumer
        channel={{ channel: "RoomUpdateChannel", room_id: roomId }}
        onReceived={handleRoomUpdateOnReceived()}
      />
      <Routes>
        <Route path="/*" element={
          <ActionCableConsumer
            channel={{ channel: "HelpeeUpdateChannel", room_id: roomId }}
            onReceived={handleHelpeeUpdateOnReceived()}
          />
        } />
        <Route path="/staff" element={
          <>
            <ActionCableConsumer
              channel={{ channel: "HelperUpdateChannel", room_id: roomId }}
              onReceived={handleHelperUpdateOnReceived()}
            />
            <ActionCableConsumer
              channel={{ channel: "ShiftHelperUpdateChannel" }}
              onReceived={handleShiftHelperUpdateOnReceived()}
            />
            <ActionCableConsumer
              channel={{ channel: "HelpProposalChannel", room_id: roomId }}
              onReceived={handleProposalOnReceived()}
            />
          </>
        } />
      </Routes>

      <RoomComponent
        onApiThrow={props.onApiThrow}
        roomId={roomId}
        onRef={roomComponentRef}
        onGoRoomList={props.onGoRoomList}
      />
    </div>
  );
}

export default Room;
